import React, { useEffect, useState } from "react";
import "./modal.css";
import { getDatabase, ref, update } from "firebase/database";
import slugify from "slugify";
import SpinnerCenter from "../Spinners/SpinnerCenter";
import moment from "moment";
import { nanoid } from "nanoid";
import fbAuth from "../../services/fbAuth";

const EditNewModuleModal = ({
  setShowEditNewModal,
  setRefreshData,
  refreshData,
  courseId,
  sectionId,
  moduleListState,
  currentModule,
}) => {
  const [moduleTitle, setModuleTitle] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [msg, setMsg] = useState("");

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  // when sectionTitle changes, update sectionId state
  useEffect(() => {
    setModuleId(
      slugify(
        moduleTitle
          .replace(/[^\w\s]/gi, "")
          .toLowerCase()
          .trim()
      ) +
        "-" +
        nanoid(5)
    );
  }, [moduleTitle]);

  async function editModule(key, value) {
    const dbRef = ref(getDatabase());

    const updates = {};

    updates[
      `sepContent/corp-courses/course-map/english/${courseId}/sectionList/${sectionId}/moduleList/${currentModule.moduleId}/${key}`
    ] = value;

    // update main course updatedAt and updatedBy
    updates[
      `sepContent/corp-courses/course-map/english/${courseId}/updatedAt`
    ] = moment().valueOf();
    updates[
      `sepContent/corp-courses/course-map/english/${courseId}/updatedBy`
    ] = fbAuth.currentUser?.email;

    await update(dbRef, updates);

    setMsg("Updated");
    setTimeout(() => {
      setMsg("");
    }, 1000);
  }

  function handleClose() {
    setShowEditNewModal(false);
    setRefreshData(!refreshData);
  }

  return (
    <div className="modal-overlay">
      {showSpinner && <SpinnerCenter />}
      <div className="modal-content" onClick={handleModalClick}>
        <div className={"column"}>
          <input
            type="text"
            defaultValue={currentModule.moduleTitle}
            name={"moduleTitle"}
            placeholder={"Module Title"}
            onChange={(e) =>
              editModule("moduleTitle", (e.target as HTMLInputElement).value)
            }
          />
          <input
            style={{ display: "none" }}
            value={currentModule.moduleId}
            type={"text"}
            name={"moduleId"}
            disabled
          />
        </div>
        <button onClick={handleClose}>Close</button> {msg}
      </div>
    </div>
  );
};

export default EditNewModuleModal;
