import React, { useEffect, useState } from "react";
import "./modal.css";
import { child, get, getDatabase, ref, update } from "firebase/database";
import SpinnerCenter from "../Spinners/SpinnerCenter";
import fbAuth from "../../services/fbAuth"; // import your modal styles
import moment from "moment";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

const EditTopicModal = ({
  setShowModal,
  setRefreshData,
  refreshData,
  courseId,
  sectionId,
  moduleId,
  currentTopicListData,
  topicListState,
}) => {
  const [topicTitle, setTopicTitle] = useState<any>(
    currentTopicListData.topicTitle
  );
  const [topicHtml, setTopicHtml] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    if (topicHtml !== "") return;
    console.log("useEffect");
    const dbRef = ref(getDatabase());
    const topicHtmlRef = `sepContent/corp-courses/topicList/english/${courseId}/${sectionId}/${moduleId}/${currentTopicListData.topicId}/topicHtml`;
    get(child(dbRef, topicHtmlRef)).then((snapshot) => {
      if (snapshot.exists()) {
        setTopicHtml(snapshot.val());
      }
    });
  }, [topicHtml]);

  async function editTopic() {
    if (topicTitle === "") return;
    if (courseId === "") return;
    if (sectionId === "") return;

    const topicData: any = {
      ...currentTopicListData,
      topicTitle: topicTitle,
      topicHtml: topicHtml,
    };
    const topicMapData: any = {
      ...currentTopicListData,
      topicTitle: topicTitle,
    };

    setShowSpinner(true);

    const updates = {};
    const dbRef = ref(getDatabase());
    updates[
      `sepContent/corp-courses/topicList/english/${courseId}/${sectionId}/${moduleId}/${currentTopicListData.topicId}`
    ] = topicData;
    updates[
      `sepContent/corp-courses/course-map/english/${courseId}/sectionList/${sectionId}/moduleList/${moduleId}/topicList/${currentTopicListData.topicId}`
    ] = topicMapData;

    // update main course updatedAt and updatedBy
    updates[
      `sepContent/corp-courses/course-map/english/${courseId}/updatedAt`
    ] = moment().valueOf();
    updates[
      `sepContent/corp-courses/course-map/english/${courseId}/updatedBy`
    ] = fbAuth.currentUser?.email;

    await update(dbRef, updates);

    setShowSpinner(false);
    setShowModal(false);
  }

  function handleClose() {
    //confirm close

    if (
      window.confirm(
        "Are you sure you want to close? Any unsaved changes will be lost."
      )
    ) {
      setShowModal(false);
    }
  }

  return (
    <div
      className="modal-overlay"
      style={{ display: "flex", flexDirection: "column", background: "grey" }}
    >
      {showSpinner && <SpinnerCenter />}
      <div className="modal-content" onClick={handleModalClick}>
        <div className={"column"}>
          <input
            type="text"
            value={topicTitle}
            name={"topicTitle"}
            placeholder={"Topic Title"}
            required={true}
            onInput={(e) => setTopicTitle((e.target as HTMLInputElement).value)}
          />
          <small>
            Preview URL:
            <a
              target={"_blank"}
              href={`https://solsedu.com/self-learning-courses/${currentTopicListData.topicId}`}
              rel="noreferrer"
            >{`https://solsedu.com/self-learning-courses/${currentTopicListData.topicId}`}</a>
          </small>

          {/*<input*/}
          {/*  type="text"*/}
          {/*  value={topicVideoUrl}*/}
          {/*  name={"topicVideoUrl"}*/}
          {/*  required={true}*/}
          {/*  placeholder={"Topic Video Url"}*/}
          {/*  onInput={(e) =>*/}
          {/*    setTopicVideoUrl((e.target as HTMLInputElement).value)*/}
          {/*  }*/}
          {/*/>*/}

          {/*<input*/}
          {/*  type="text"*/}
          {/*  value={topicImage}*/}
          {/*  name={"topicImage"}*/}
          {/*  placeholder={"Topic Image"}*/}
          {/*  onInput={(e) => setTopicImage((e.target as HTMLInputElement).value)}*/}
          {/*/>*/}

          <SunEditor
            setOptions={{
              height: "500",
              buttonList: [
                [
                  "undo",
                  "redo",
                  "font",
                  "fontSize",
                  "formatBlock",
                  "paragraphStyle",
                  "blockquote",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "fontColor",
                  "hiliteColor",
                  "textStyle",
                  "removeFormat",
                  "align",
                  "horizontalRule",
                  "list",
                  "lineHeight",
                  "table",
                  "link",
                  "image",
                  "video",
                  "audio",
                  "codeView",
                  "preview",
                  "fullScreen",
                ],
              ],
              imageUrlInput: true,
              videoUrlInput: true,
              charCounter: true,
            }}
            setDefaultStyle={
              "font-family: 'Verdana', sans-serif;font-size: 16px;"
            }
            defaultValue={topicHtml}
            setContents={topicHtml}
            onChange={(content) => setTopicHtml(content)}
          />
        </div>
      </div>
      <div className="row">
        <div
          className="column"
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            background: "grey",
            padding: "10px",
          }}
        >
          <button onClick={editTopic}>Save</button>{" "}
          <button onClick={handleClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default EditTopicModal;
