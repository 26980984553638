import React, { useEffect, useState } from "react";
import "./modal.css";
import { getDatabase, ref, update } from "firebase/database";
import slugify from "slugify";
import SpinnerCenter from "../Spinners/SpinnerCenter";
import fbAuth from "../../services/fbAuth"; // import your modal styles
import moment from "moment";

const EditNewSectionModal = ({
  showModal,
  setRefreshData,
  refreshData,
  courseId,
  sectionListState,
  currentSection,
}) => {

  const [msg, setMsg] = useState("");

  const handleModalClick = (event) => {
    event.stopPropagation();
  };



  async function updateSection(key, value) {
    const updates = {};
    const dbRef = ref(getDatabase());


    updates[
      `sepContent/corp-courses/course-map/english/${courseId}/sectionList/${currentSection.sectionId}/${key}`
    ] = value;

    // update main course updatedAt and updatedBy
    updates[
      `sepContent/corp-courses/course-map/english/${courseId}/updatedAt`
    ] = moment().valueOf();
    updates[
      `sepContent/corp-courses/course-map/english/${courseId}/updatedBy`
    ] = fbAuth.currentUser?.email;

    await update(dbRef, updates);

    setMsg("Updated");
    setTimeout(() => {
      setMsg("");

    }, 1000);
  }
  function handleClose() {
    showModal(false);
    setRefreshData(!refreshData);
  }
  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={handleModalClick}>
        <div className={"column"}>
          <input
            type="text"
            defaultValue={currentSection.sectionTitle}
            name={"sectionTitle"}
            placeholder={"Section Title"}
            onChange={(e) =>
              updateSection(
                "sectionTitle",
                (e.target as HTMLInputElement).value
              )
            }
          />
          <input
            value={currentSection.sectionId}
            type={"text"}
            name={"sectionId"}
            disabled
          />
        </div>

        <button onClick={handleClose}>Close</button>
        {msg}
      </div>
    </div>
  );
};

export default EditNewSectionModal;
