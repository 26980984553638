import React from "react";
import { Link } from "react-router-dom";
import AppContainer from "../../containers/AppContainer";
import { userRoleCheck } from "../../services/user.services";
import fbAuth from "../../services/fbAuth";

const Home = () => {
  const linksLeft = [
    // {
    //   to: "/lcBookings",
    //   title: "Live Class booking report for SOLS 24/7",
    //   details: "check out bookings",
    // },
    {
      to: "/corporate-course-list",
      title: "Corporate Course List",
      details:
        "View the list of corporate courses, and add new ones, add sections, modules, topics",
    },

    {
      to: "/add-user-to-course",
      title: "Add Users to Corporate Course List ",
      details: "Add user emails for corporate courses\n",
    },
    {
      to: "/view-user-progress",
      title: "Progress report for Corporate Course List",
      details: "View users progress for corporate courses",
    },
  ];
  const linksRight = [
    {
      to: "/id-generator",
      title: "Generate Ids (UUID, NANOID, FBID)",
      details: "check out bookings",
    },
    {
      to: "/fake-data-generator",
      title: "Generate Fake Data",
      details:
        "Generate massive amounts of fake (but realistic) data for testing and development.\n" +
        "\n",
    },
    {
      to: "/generate-cs-data",
      title: "Generate Charge Singh Mock Data",
      details:
        "Generate massive amounts of fake (but realistic) data for testing and development.\n" +
        "\n",
    },
  ];

  async function checkUser() {
    const user = await fbAuth.currentUser;
    if (user) {
      const isAdmin = await userRoleCheck(user.uid);
      console.log("user is logged in" + isAdmin);
      console.log(user.uid);
      if (!isAdmin) {
        fbAuth.signOut();
      }
    }
  }

  checkUser();

  return (
    <AppContainer headerTitle={"Dashboard"}>
      <div
        className="container"
        style={{
          width: "70%",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <div className="row">
          {linksLeft.map((link) => (
            <div key={link.to} className={" column links"}>
              <Link to={link.to} className={"remove-formatting"}>
                <div className={"card"}>
                  <div style={{ height: "200px" }}>
                    <h5>
                      <b>{link.title} </b>{" "}
                    </h5>
                    <p>{link.details}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}

          {/*<div className="column">*/}
          {/*  {linksRight.map((link) => (*/}
          {/*    <div key={link.to} className={"links"}>*/}
          {/*      <Link to={link.to}>*/}
          {/*        <b> {link.title} </b>*/}
          {/*        <br />*/}
          {/*        <small>{link.details}</small>*/}
          {/*      </Link>*/}
          {/*    </div>*/}
          {/*  ))}*/}
          {/*</div>*/}
        </div>
      </div>
    </AppContainer>
  );
};

export default Home;
