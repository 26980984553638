import React, { useState } from "react";
import "./modal.css";
import SpinnerCenter from "../Spinners/SpinnerCenter";
import { createNewCorpCourse } from "../../services/coporatecourses.services";
import moment from "moment";
import { getDatabase, ref, update } from "firebase/database";
import fbAuth from "../../services/fbAuth";

const EditNewCourseModal = ({
  setModalOpen,
  setRefreshData,
  refreshData,
  currentCourse,
}) => {
  const [isCreateNewCourseModel, setCreateNewCourseModel] = useState(false);

  const [courseTitle, setCourseTitle] = useState(currentCourse.courseTitle);
  const [courseId, setCourseId] = useState(currentCourse.courseId);
  const [courseDescription, setCourseDescription] = useState(
    currentCourse.courseDescription
  );
  const [courseImage, setCourseImage] = useState(currentCourse.courseImage);
  const [msg, setMsg] = useState("");

  const handleModalClick = (event) => {
    event.stopPropagation();
  };


  function handleClose() {
    setModalOpen(false);
    setRefreshData(!refreshData);
  }

  async function updateCourse(key, value) {
    const updates = {};
    const dbRef = ref(getDatabase());
    //update course data in course-map
    updates[`sepContent/corp-courses/course-map/english/${courseId}/courseId`] =
      courseId;
    updates[`sepContent/corp-courses/course-map/english/${courseId}/${key}`] =
      value;
    updates[
      `sepContent/corp-courses/course-map/english/${courseId}/updatedAt`
    ] = moment().valueOf();
    updates[
      `sepContent/corp-courses/course-map/english/${courseId}/updatedBy`
    ] = fbAuth.currentUser?.email;

    await update(dbRef, updates);

    setMsg("Updated");
    setTimeout(() => {
      setMsg("");
    }, 1000);
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={handleModalClick}>
        <h5>Edit Course</h5>
        <div className={"column"}>
          <input
            required
            type="text"
            defaultValue={courseTitle}
            name={"courseTitle"}
            placeholder={"Course Title  (required)"}
            onChange={(e) =>
              updateCourse("courseTitle", (e.target as HTMLInputElement).value)
            }
          />

          <input
            required
            placeholder={"Course Image URL (optional)"}
            defaultValue={courseImage}
            type={"text"}
            name={"courseImage"}
            onInput={(e) =>
              updateCourse("courseImage", (e.target as HTMLInputElement).value)
            }
          />
          <input
            type="text"
            placeholder={"Course Description (optional)"}
            defaultValue={courseDescription}
            name={"courseDescription"}
            onInput={(e) =>
              updateCourse(
                "courseDescription",
                (e.target as HTMLInputElement).value
              )
            }
          />

          <input
            value={courseId}
            type={"text"}
            name={"courseId"}
            disabled
            placeholder={"Course Id (Autogenerated)"}
          />
        </div>

        <button onClick={handleClose}>Close</button>
        <small>{msg}</small>
      </div>
    </div>
  );
};

export default EditNewCourseModal;
