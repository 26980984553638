import React, { useEffect, useState } from "react";
import AppContainer from "../../containers/AppContainer";
import { child, get, getDatabase, ref } from "firebase/database";
import SpinnerCenter from "../../components/Spinners/SpinnerCenter";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import EditNewSectionModal from "../../components/ModalPopups/EditNewSectionModal";
import { deleteSections } from "../../services/coporatecourses.services";
import CreateNewSectionModal from "../../components/ModalPopups/CreateNewSectionModal";

const slugify = require("slugify");

export async function getSectionList(courseId: string) {
  const dbRef = ref(getDatabase());

  const childRef = `sepContent/corp-courses/course-map/english/${courseId}/sectionList`;
  const snapshot = await get(child(dbRef, childRef));
  if (snapshot.exists()) {
    return snapshot.val();
  }
}

interface ParamsProps {
  courseId: string;
}

const CorporateSectionList = ({ sectionListData }) => {
  let { courseId } = useParams<ParamsProps>();

  const [sectionListState, setSectionListState] = useState<any>([]);
  const [currentSection, setCurrentSection] = useState<any>({});
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);
  const [showAddModal, setShowAddNewModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [refreshData, setRefreshData] = useState(false);
  useEffect(() => {
    const call = async () => {
      setShowSpinner(true);
      const data = await getSectionList(courseId);
      if (data) {
        const dataWithKey = Object.entries(data).map(
          ([key, value]) =>
            ({
              sectionId: key,
              ...(value as any),
            } as any)
        );

        setSectionListState(Object.values(dataWithKey));
      }
      setShowSpinner(false);
    };

    call();
  }, [refreshData]);

  useEffect(() => {
    const call = async () => {};

    call();
  }, [isCourseModalOpen]);

  function editSection(sectionId) {
    const currentSectionLocal = sectionListState.find(
      (item) => item.sectionId === sectionId
    );
    setCurrentSection(currentSectionLocal);
    setShowEditModal(true);
  }

  async function deleteSection(sectionId) {
    if (!window.confirm("Are you sure you want to delete this course?")) {
      return;
    }

    await deleteSections(sectionId, courseId).then((res) => {
      setSectionListState([]);
      setRefreshData(!refreshData);
    });
  }

  return (
    <AppContainer headerTitle={"Dashboard"}>
      <div className="container">
        {showSpinner && <SpinnerCenter />}
        <div className="row">
          <div
            className="column"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {/*back button*/}
            <Link to={"/corporate-course-list"}>
              <button className={"button button-clear"}>Back</button>
            </Link>

            <h5> Sections </h5>
            <button
              onClick={() => setShowAddNewModal(true)}
              className={"button"}
            >
              + Add New Section
            </button>
          </div>
        </div>
        <div className="row">
          <div className="column">
            {!sectionListState.length && <p> No data</p>}
          </div>
        </div>
        <div className="row">
          <div className="column">
            <ul style={{ display: "flex", flexDirection: "column" }}>
              {sectionListState &&
                sectionListState.length > 0 &&
                _.sortBy(sectionListState, "createdAt").map((item, index) => (
                  <div className={"card"} key={item.sectionId + index}>
                    <div className={"row"}>
                      <div className={"column"}>
                        <h5>
                          <b>{item.sectionTitle}</b>
                        </h5>
                      </div>
                      <div className="column">
                        <Link
                          to={`/corporate-course-list/module-list/${courseId}/${item.sectionId}`}
                        >
                          <button className={"button button-clear"}>
                            Modules
                          </button>
                        </Link>
                        <button
                          className={"button button-clear"}
                          onClick={() => editSection(item.sectionId)}
                        >
                          Edit
                        </button>
                        <button
                          className={"button button-clear"}
                          onClick={() => deleteSection(item.sectionId)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </ul>

            <div className="row">
              <div className="column"></div>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div className={"column"}>
            <div className="row ">
              {showAddModal && (
                <CreateNewSectionModal
                  setShowAddNewModal={setShowAddNewModal}
                  setRefreshData={setRefreshData}
                  refreshData={refreshData}
                  courseId={courseId}
                  sectionListState={sectionListState}
                />
              )}
            </div>{" "}
            <div className="row ">
              {showEditModal && (
                <EditNewSectionModal
                  showModal={setShowEditModal}
                  setRefreshData={setRefreshData}
                  refreshData={refreshData}
                  courseId={courseId}
                  currentSection={currentSection}
                  sectionListState={sectionListState}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default CorporateSectionList;
