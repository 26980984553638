import { ref, getDatabase, child, get } from "firebase/database";

export const userRoleCheck = async (userId: string) => {
  const dbRef = ref(getDatabase());

  const childRef = `admins/${userId}`;
  const snapshot = await get(child(dbRef, childRef));
  console.log(snapshot.val());
  return snapshot.exists() && !!snapshot.val() === true;
};
