import React, { useEffect, useState } from "react";
import AppContainer from "../../containers/AppContainer";
import { child, get, getDatabase, ref } from "firebase/database";
import SpinnerCenter from "../../components/Spinners/SpinnerCenter";
import { Link, useParams } from "react-router-dom";
import CreateNewModuleModal from "../../components/ModalPopups/CreateNewModuleModal";
import _ from "lodash";
import EditNewModuleModal from "../../components/ModalPopups/EditNewModuleModal";
import { deleteModules } from "../../services/coporatecourses.services";

const slugify = require("slugify");

export async function getModuleList(courseId: string, sectionId: string) {
  const dbRef = ref(getDatabase());

  const childRef = `sepContent/corp-courses/course-map/english/${courseId}/sectionList/${sectionId}/moduleList`;
  const snapshot = await get(child(dbRef, childRef));
  if (snapshot.exists()) {
    return snapshot.val();
  }
}

interface ParamsProps {
  courseId: string;
  sectionId: string;
}

const CorporateModuleList = () => {
  let { courseId, sectionId } = useParams<ParamsProps>();

  const [moduleListState, setModuleListState] = useState<any>([]);
  const [currentModule, setCurrentModule] = useState<any>({});
  const [showAddModal, setShowAddNewModal] = useState(false);
  const [showEditModal, setShowEditNewModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [refreshData, setRefreshData] = useState(false);
  useEffect(() => {
    const call = async () => {
      setShowSpinner(true);
      const data = await getModuleList(courseId, sectionId);
      if (data) {
        const dataWithKey = Object.entries(data).map(
          ([key, value]) =>
            ({
              moduleId: key,
              ...(value as any),
            } as any)
        );
        setModuleListState(Object.values(dataWithKey));
      }
      setShowSpinner(false);
    };

    call();
  }, [refreshData]);

  function editModule(moduleId) {
    const module = moduleListState.find((item) => item.moduleId === moduleId);
    // alert(JSON.stringify(module));
    setCurrentModule(module);
    setShowEditNewModal(true);
  }

  async function deleteModule(moduleId) {
    if (!window.confirm("Are you sure you want to delete this?")) {
      return;
    }

    await deleteModules(moduleId, sectionId, courseId).then((res) => {
      setModuleListState([]);
      setRefreshData(!refreshData);
    });
  }

  return (
    <AppContainer headerTitle={"Dashboard"}>
      <div className="container">
        {showSpinner && <SpinnerCenter />}
        <div className="row">
          <div
            className="column"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {/*back button*/}
            <Link to={`/corporate-course-list/section-list/${courseId}`}>
              <button className={"button button-clear"}>Back</button>
            </Link>

            <h5>Modules</h5>
            <button
              onClick={() => setShowAddNewModal(true)}
              className={"button"}
            >
              + Add New Module
            </button>
          </div>
        </div>
        <div className="row">
          <div className="column">
            {moduleListState.length > 0 ? (
              <small>Total: {moduleListState.length}</small>
            ) : (
              <p> No data</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="column">
            <ul style={{ display: "flex", flexDirection: "column" }}>
              {moduleListState &&
                moduleListState.length > 0 &&
                _.sortBy(moduleListState, "moduleIndex").map((item, index) => (
                  <div className={"card"} key={item.moduleId + index}>
                    <div className={"row "}>
                      <div className={"column"}>
                        <h5>
                          <b>{item.moduleTitle}</b>
                        </h5>
                      </div>
                      <div className="column">
                        <Link
                          to={`/corporate-course-list/topic-list/${courseId}/${sectionId}/${item.moduleId}`}
                        >
                          <button className={"button button-clear"}>
                            Topics
                          </button>
                        </Link>
                        <button
                          className={"button button-clear"}
                          onClick={() => editModule(item.moduleId)}
                        >
                          Edit
                        </button>
                        <button
                          className={"button button-clear"}
                          onClick={() => deleteModule(item.moduleId)}
                        >
                          Delete
                        </button>
                        {/*<button className={"button button-clear"}>*/}
                        {/*  Move Up*/}
                        {/*</button>*/}
                      </div>
                    </div>
                  </div>
                ))}
            </ul>
          </div>
        </div>
        <div className={"row"}>
          <div className={"column"}>
            <div className="row ">
              {showAddModal && (
                <CreateNewModuleModal
                  setShowModal={setShowAddNewModal}
                  setRefreshData={setRefreshData}
                  refreshData={refreshData}
                  courseId={courseId}
                  sectionId={sectionId}
                  moduleListState={moduleListState}
                />
              )}
            </div>
            <div className="row ">
              {showEditModal && (
                <EditNewModuleModal
                  setShowEditNewModal={setShowEditNewModal}
                  setRefreshData={setRefreshData}
                  refreshData={refreshData}
                  courseId={courseId}
                  sectionId={sectionId}
                  moduleListState={moduleListState}
                  currentModule={currentModule}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default CorporateModuleList;
