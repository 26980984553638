import React, { ChangeEvent, useEffect, useState } from "react";
import AppContainer from "../containers/AppContainer";
import { getCorporateCoursesList } from "./CorporateCourses/corporate-course-list";
import _, { add, set } from "lodash";
import { functions } from "../services/firebaseApp";
import { httpsCallable } from "firebase/functions";
import SpinnerCenter from "../components/Spinners/SpinnerCenter";
import {
  addCorporateCompany,
  getCorporateCompanyList,
} from "../services/coporatecourses.services";
import slugify from "slugify";

const AddUserToCourse = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [courseListState, setCourseListState] = useState<any>([]);
  const [companyListState, setCompanyListState] = useState<any>([]);

  const [currentCourseId, setCurrentCourseId] = useState<any>("");
  const [currentCourseTitle, setCurrentCourseTitle] = useState<any>("");
  const [refreshData, setRefreshData] = useState(false);
  const [currentEmail, setCurrentEmail] = useState<string>("");
  const [confirmUser, setConfirmUser] = useState(false);
  const [emails, setEmails] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [currentCompanyName, setCurrentCompanyName] = useState<any>("");
  const [currentCompanyId, setCurrentCompanyId] = useState<any>("");
  const [currentFullName, setCurrentFullName] = useState<any>("");

  const handleTextareaChange = (event) => {
    // const emailArray = event.target.value.split(",");
    // setEmails(emailArray.map((email) => email.trim()));

    const email = event.target.value.trim();

    setCurrentEmail(email);
  };

  const handleNameChange = (event) => {
    // const emailArray = event.target.value.split(",");
    // setEmails(emailArray.map((email) => email.trim()));

    const name = event.target.value as string;

    setCurrentFullName(name);
  };

  useEffect(() => {
    const call = async () => {
      setShowSpinner(true);
      const data = await getCorporateCoursesList();
      const companyData = await getCorporateCompanyList();

      if (!data || !companyData) {
        setShowSpinner(false);

        return;
      }
      setCompanyListState(Object.values(companyData));
      setCourseListState(Object.values(data));
      setShowSpinner(false);
    };

    call();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setNotificationMessage("");
    }, 2000);
  }, [notificationMessage]);

  function handleOnChangeCourse(e) {
    const value = e.target.value;
    const text = e.target.options[e.target.selectedIndex].text;
    setCurrentCourseId(value);
    setCurrentCourseTitle(text);
  }

  function createUser(email: any) {
    setShowSpinner(true);
    function validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    }
    if (!validateEmail(email)) {
      alert("Please enter a valid email");
      setShowSpinner(false);
      return;
    }
    if (!email || !currentCourseId || !currentCourseTitle) {
      console.log("Please complete the form");
      setShowSpinner(false);

      return;
    }
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const createUserAndCourse = httpsCallable(functions, "createUserAndCourse");

    const data = {
      fullName: currentFullName,
      email: email,
      courseId: currentCourseId,
      courseTitle: currentCourseTitle,
      companyName: currentCompanyName,
      companyId: currentCompanyId,
    };
    console.log("data", data);
    createUserAndCourse(data)
      .then((result: any) => {
        if (result) {
          setNotificationMessage(result.data.message);
          setShowSpinner(false);
          // alert(result.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setShowSpinner(false);
      });
  }

  async function handleAddNewCompany(event: any) {
    event.preventDefault();
    const companyName = window.prompt("Enter Company Name");
    if (!companyName) return;
    const companyId = slugify(companyName.trim().toLowerCase());
    const data = {
      companyId: companyId,
      companyName: companyName,
    };

    const addCompany = await addCorporateCompany(companyId, data);
    if (addCompany) {
      setShowSpinner(true);
      const companyData = await getCorporateCompanyList();
      setCompanyListState(Object.values(companyData));
      setCurrentCompanyName(companyName);
      setCurrentCompanyId(companyId);
    } else {
      alert("Company not added");
    }
    setShowSpinner(false);
  }

  function handleCompanyDropdownChange(
    event: ChangeEvent<HTMLSelectElement>
  ): void {
    const value = event.target.value;
    const text = event.target.options[event.target.selectedIndex].text;
    setCurrentCompanyName(text);
    setCurrentCompanyId(value);
  }

  return (
    <AppContainer headerTitle={"Add User"}>
      <div
        className="container"
        style={{
          width: "90%",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <div className="row">
          <div className="column">
            {showSpinner && <SpinnerCenter />}
            <h5>Users Creation</h5>
            <div className="card-vertical">
              {notificationMessage && (
                <div className="notificationMessage">
                  <small>{notificationMessage}</small>
                </div>
              )}
              <input
                type="text"
                value={currentFullName}
                name={currentFullName}
                placeholder="Enter Name"
                onInput={handleNameChange}
              />

              <input
                type="text"
                value={currentEmail}
                name={currentEmail}
                placeholder="Enter a single email"
                onInput={handleTextareaChange}
              />
              <div className="row">
                <div className="column">
                  <select
                    name={currentCompanyId}
                    placeholder="Company Name"
                    value={currentCompanyId}
                    onChange={handleCompanyDropdownChange}
                  >
                    <option value="sols-247">Select Company</option>
                    {companyListState &&
                      companyListState.length > 0 &&
                      _.sortBy(companyListState, "createdAt").map((item) => (
                        <option key={item.companyId} value={item.companyId}>
                          {item.companyName + " - " + item.companyId}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="column">
                  <small>If company don't exists then create a new one.</small>{" "}
                  <br />
                  <button onClick={handleAddNewCompany}>Add New Company</button>
                </div>
              </div>

              <select
                value={currentCourseId}
                name={currentCourseId}
                onChange={(e) => handleOnChangeCourse(e)}
              >
                <option value={""}>Select Course</option>
                {courseListState &&
                  courseListState.length > 0 &&
                  _.sortBy(courseListState, "createdAt").map((item) => (
                    <option key={item.courseId} value={item.courseId}>
                      {item.courseTitle}
                    </option>
                  ))}
              </select>
              <button
                disabled={
                  !currentEmail ||
                  !currentCourseId ||
                  !currentCompanyName ||
                  !currentFullName
                }
                className={"button button-outline"}
                onClick={() => createUser(currentEmail)}
              >
                Create User and Assign Course
              </button>
              {/* <button
                className={"button button-clear"}
                onClick={() => setCurrentEmail("")}
              >
                reset
              </button> */}
            </div>
          </div>
          {/*<div className={"column"} style={{ marginTop: "20px" }}>*/}
          {/*  <div className="">*/}
          {/*    <h5>Confirm and Take next action</h5>*/}
          {/*    <div className="user-list">*/}
          {/*      {emails.length &&*/}
          {/*        emails.length > 0 &&*/}
          {/*        emails.map((email) => (*/}
          {/*          <div className="card user-list-item">*/}
          {/*            <div className="user-list-item-email">{email}</div>*/}
          {/*            <div className="user-list-item-action">*/}
          {/*              <button*/}
          {/*                className={"button button-clear"}*/}
          {/*                onClick={() => createUser(email)}*/}
          {/*              >*/}
          {/*                Confirm*/}
          {/*              </button>*/}
          {/*            </div>*/}
          {/*          </div>*/}
          {/*        ))}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </AppContainer>
  );
};

export default AddUserToCourse;
