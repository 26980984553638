import React from "react";
import "./SpinnerCenter.css";

interface Props {
  spinnerText?: string;
}
function SpinnerCenter({ spinnerText }: Props) {
  return (
    <div className="spinner-overlay">
      <div className="spinner-center">
        <div className="spinner"></div>
        {/*<div className="spinnerText">{spinnerText || "Please Wait.."}</div>*/}
      </div>
    </div>
  );
}

export default SpinnerCenter;
