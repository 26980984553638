import React, { useEffect, useState } from "react";
import AppContainer from "../containers/AppContainer";
import _, { get } from "lodash";
import {
  getCorporateCompanyList,
  getUsersListPerCompanyPerCourse,
} from "../services/coporatecourses.services";
import { getCorporateCoursesList } from "./CorporateCourses/corporate-course-list";
import { CSVLink, CSVDownload } from "react-csv";

interface Props {
  // Define props here
}

const ViewProgressReport: React.FC<Props> = (props) => {
  const [currentCompanyId, setCurrentCompanyId] = useState<any>("");
  const [companyListState, setCompanyListState] = useState<any>([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [courseListState, setCourseListState] = useState<any>([]);
  const [currentCourseId, setCurrentCourseId] = useState<any>("");
  const [currentCourseTitle, setCurrentCourseTitle] = useState<any>("");
  const [userReport, setUserReport] = useState<any>([]);
  const [csvData, setCsvData] = useState<any>([]);

  useEffect(() => {
    const call = async () => {
      setShowSpinner(true);
      const data = await getCorporateCoursesList();
      const companyData = await getCorporateCompanyList();

      if (!data || !companyData) {
        setShowSpinner(false);

        return;
      }
      setCompanyListState(Object.values(companyData));
      setCourseListState(Object.values(data));
      setShowSpinner(false);
    };

    call();
  }, []);
  function handleOnChangeCourse(e) {
    const value = e.target.value;
    const text = e.target.options[e.target.selectedIndex].text;
    console.log("value", value);
    console.log("text", text);
    setCurrentCourseId(value);
    setCurrentCourseTitle(text);
  }
  function handleSelectCompany(value: string): void {
    // find all the students in the company and show progress
  }

  async function handleSearch(): Promise<void> {
    if (!currentCompanyId) {
      alert("Please select company");
      return;
    }
    if (!currentCourseId) {
      alert("Please select course");
      return;
    }

    //get firebase users list progress
    const usersListPerCompanyPerCourse = await getUsersListPerCompanyPerCourse(
      currentCompanyId,
      currentCourseId
    );
    setUserReport(Object.values(usersListPerCompanyPerCourse));

    const csvData = Object.values(usersListPerCompanyPerCourse).map(
      (item: any) => {
        return [
          item.companyName,
          item.userName,
          currentCourseTitle,
          item.userProgress,
          item.userEmail,
        ];
      }
    );

    const newCsvData = [
      ["Company Name", "User Name", "Course Name", "User Progress", "Email"],
      ...csvData,
    ];

    setCsvData(newCsvData);
    console.log(
      "%cview-user-progress.tsx line:85 newCsvData",
      "color: #007acc;",
      newCsvData
    );
  }

  // Define component logic here

  return (
    // Define component JSX here
    <AppContainer headerTitle={"Add User"}>
      <div className="container">
        <div className="row">
          <div className="column">
            <div className="card">
              <select
                name={currentCompanyId}
                placeholder="Write Company Name"
                value={currentCompanyId}
                onChange={(e) => setCurrentCompanyId(e.target.value)}
              >
                <option value="">Select Company</option>
                {companyListState &&
                  companyListState.length > 0 &&
                  _.sortBy(companyListState, "createdAt").map((item) => (
                    <option key={item.companyId} value={item.companyId}>
                      {item.companyName + " - " + item.companyId}
                    </option>
                  ))}
              </select>

              <select
                value={currentCourseId}
                name={currentCourseId}
                onChange={(e) => handleOnChangeCourse(e)}
              >
                <option value={""}>Select Course</option>
                {courseListState &&
                  courseListState.length > 0 &&
                  _.sortBy(courseListState, "createdAt").map((item) => (
                    <option key={item.courseId} value={item.courseId}>
                      {item.courseTitle}
                    </option>
                  ))}
              </select>
              <button
                disabled={!currentCompanyId || !currentCourseId}
                onClick={handleSearch}
                className="button button-outline"
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column text-center">
            <h3 className="text-center">Progress Report</h3>
            {userReport && userReport.length > 0 && (
              <CSVLink data={csvData}>Download</CSVLink>
            )}
          </div>
        </div>
        <div className="row">
          <div className="column">
            <div className="card">
              <table>
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Student Name</th>
                    <th>Course Title</th>
                    <th>Progress</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {userReport &&
                    userReport.length > 0 &&
                    userReport.map((item) => (
                      <tr key={item.userId}>
                        <td>{item.companyName}</td>
                        <td>{item.userName}</td>
                        <td>{currentCourseTitle}</td>
                        <td>{item.userProgress}</td>
                        <td>{item.userEmail}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default ViewProgressReport;
