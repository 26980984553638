import React, {useRef, useState} from 'react';
import AppContainer from "../../containers/AppContainer";
import {getLcBookings} from "../../services/firebaseNodes";
import {LcBookingsInterface} from "../../interfaces/common";
import moment from "moment";


const LcBookings = () => {

  const textAreaRef = useRef();
  const [emailList, setEmailList] = useState("");
  const [startDateTsState, setStartDateTsState] = useState<Date>(new Date());
  const [endDateTsState, setEndDateTsState] = useState<Date>(new Date());
  const [lcBookingStateData, setLcBookingStateData] = useState<LcBookingsInterface[]>([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [lcCount, setLcCount] = useState({});

  async function handleClick() {
    setErrorMsg("")
    const counts = {}
    const startDate = new Date(startDateTsState).getTime();
    const endDate = new Date(endDateTsState).getTime();
    const getLcBookingJson = await getLcBookings(startDate, endDate);
    if (getLcBookingJson.exists()) {

      const lcBookingDataArray: LcBookingsInterface[] = [];
      const lcBookingDataLcList: string[] = []
      getLcBookingJson.forEach(lcBookingItem => {
        lcBookingDataArray.push(lcBookingItem.val());
        lcBookingDataLcList.push(lcBookingItem.val().lcId)
      });
      setTotalCount(getLcBookingJson.size)
      setLcBookingStateData(lcBookingDataArray);

      lcBookingDataLcList.forEach((item: string) => {
        console.log(item)
        // @ts-ignore
        counts[item] = (counts[item] || 0) + 1;
      })

      setLcCount(counts)


      console.log(counts)


    } else {
      setErrorMsg("No data found");
    }
  }

  function handleOnChange(e: any) {
    if (e.target.value) {
      setEmailList(e.target.value.split('\n'));
      console.log(emailList);

    }
  }


  return (
    <AppContainer headerTitle={"Class Report"}>
      <div className="container">
        <div className="row">
          <div className="column">

            <input type={"date"} onChange={(e: any) => setStartDateTsState(e.target.value)}/>
          </div>
          <div className="column">

            <input type={"date"} onChange={(e: any) => setEndDateTsState(e.target.value)}/>
          </div>
          <div className="column">

            <button className="button button-outline" onClick={handleClick}>Submit</button>
          </div>

        </div>

        {errorMsg !== "" ? errorMsg : ""}
        <div className="row">


          <div className="column">
            <h3>Live Class bookings per class</h3>
            <table className={"bg-light"}>
              <th>Live Class Title</th>
              <th>Bookings</th>
              {

                Object.entries(lcCount).map((value) =>
                  <tr key={value[0]}>
                    <td>{String(value[0])}</td>

                    <td>{String(value[1])}</td>


                  </tr>
                )
              }

            </table>
          </div>

        </div>
        <hr/>
        <div className="row">
          <h3>Booking Info per student</h3>

        </div>
        <div className="row">


          <table className={"bg-light"}>
            <thead>
            <tr>
              <th>userId</th>
              <th>lcId</th>
              <th>bookingTs</th>
              <th>userName</th>
              <th>userEmail</th>
              <th>teacherId</th>
              <th>lcCategoryId</th>
              <th>lcCourseId</th>
              <th>lcEndDateTs</th>
              <th>lcLevelId</th>
              <th>lcProgramId</th>
              <th>lcStartDateTs</th>
              <th>lcSupportId</th>
              <th>lcTargetLang</th>
              <th>Support</th>
              <th>lpId</th>
              <th>teacherName</th>
              <th>Live Class</th>
            </tr>
            </thead>
            <tbody>
            {
              lcBookingStateData.length > 0 ? lcBookingStateData.map((item, index) =>
                <tr key={index}>
                  <td>{item.userId}</td>
                  <td>{item.lcId}</td>
                  <td>{item.bookingTs ? moment(item.bookingTs).format("MMM YYYY") : ""}</td>
                  <td>{item.userName}</td>
                  <td>{item.userEmail}</td>
                  <td>{item.teacherId}</td>
                  <td>{item.lcCategoryId}</td>
                  <td>{item.lcCourseId}</td>
                  <td>{item.lcEndDateTs ? moment(item.lcEndDateTs).format("MMM YYYY") : ""}</td>
                  <td>{item.lcLevelId}</td>
                  <td>{item.lcProgramId}</td>
                  <td>{item.lcStartDateTs ? moment(item.lcStartDateTs).format("MMM YYYY") : ""}</td>
                  <td>{item.lcSupportId}</td>
                  <td>{item.lcTargetLang}</td>
                  <td>{item.lcSupportId}</td>
                  <td>{item.lpId}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.lcId}</td>
                </tr>
              ) : null
            }

            </tbody>
          </table>
        </div>
      </div>
    </AppContainer>
  );
}


export default LcBookings;
