export const getMaxFromArray = (arr, key) => {
  if (arr.length === 0) return 0;
  const mappedArray = arr.map((obj) => Number(obj[key]));
  if (mappedArray.every((value) => !isNaN(value))) {
    return Math.max(...mappedArray);
  }
  return 0;
};

export const getMinFromArray = (arr, key) => {
  return Math.min(...arr.map((obj) => obj[key]));
};

export const moveArrayItem = (arr, oldIndex, newIndex, key) => {
  const arrLength = arr.length;
  const firstIndex = arr.findIndex((obj) => obj[key] === oldIndex);
  const secondIndex = arr.findIndex((obj) => obj[key] === newIndex);
};
