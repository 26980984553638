import * as React from "react";
import { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import fbAuth from "../../services/fbAuth";

interface Props {
  children: ReactNode;
  headerTitle: string;
}

const AppContainer: FC<Props> = ({ children, headerTitle }) => {
  const user = fbAuth.currentUser?.email;

  return (
    <>
      <div>
        <div className={"navbar"}>
          <Link className={"navbar-item"} to={"/"}>
            Home
          </Link>

          {fbAuth.currentUser ? (
            <button className={"button-nav"} onClick={() => fbAuth.signOut()}>
              {" "}
              Logout <small>[{user} ]</small>
            </button>
          ) : null}
        </div>
      </div>
      <div>{children}</div>
    </>
  );
};
export default AppContainer;
