import React, { useEffect, useState } from "react";
import AppContainer from "../../containers/AppContainer";
import { child, get, getDatabase, ref, set } from "firebase/database";
import SpinnerCenter from "../../components/Spinners/SpinnerCenter";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import CreateNewTopicModal from "../../components/ModalPopups/CreateNewTopicModal";
import EditTopicModal from "../../components/ModalPopups/EditTopicModal";
import { TopicItem } from "../../interfaces/corporate-courses.interface";
import { deleteTopics } from "../../services/coporatecourses.services";

const slugify = require("slugify");

export async function getTopicList(
  courseId: string,
  sectionId: string,
  moduleId: string
) {
  const dbRef = ref(getDatabase());

  const topicListRef = `sepContent/corp-courses/course-map/english/${courseId}/sectionList/${sectionId}/moduleList/${moduleId}/topicList`;
  const snapshot = await get(child(dbRef, topicListRef));
  if (snapshot.exists()) {
    return snapshot.val();
  }
}

interface ParamsProps {
  courseId: string;
  sectionId: string;
  moduleId: string;
}

const CorporateTopicList = () => {
  let { courseId, sectionId, moduleId } = useParams<ParamsProps>();

  const [topicListState, setTopicListState] = useState<any>([]);
  const [currentTopic, setCurrentTopic] = useState<TopicItem>({} as TopicItem);

  const [showAddModal, setShowAddNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [refreshData, setRefreshData] = useState(false);
  useEffect(() => {
    const call = async () => {
      setShowSpinner(true);
      const data = await getTopicList(courseId, sectionId, moduleId);
      if (data) {
        const dataWithKey = Object.entries(data).map(
          ([key, value]) =>
            ({
              topicId: key,
              ...(value as any),
            } as any)
        );
        setTopicListState(Object.values(dataWithKey));
      }
      setShowSpinner(false);
    };

    call();
  }, [refreshData]);

  function handleEditModal(item: TopicItem) {
    setCurrentTopic(item);
    setShowEditModal(true);
  }

  async function deleteTopic(topicId) {
    console.log("deleteTopic", topicId);
    if (!window.confirm("Are you sure you want to delete?")) {
      return;
    }

    await deleteTopics(topicId, moduleId, sectionId, courseId).then(() => {
      setTopicListState([]);
      setRefreshData(!refreshData);
    });
  }

  function handleTopicChange(
    e: React.ChangeEvent<HTMLSelectElement>,
    item: TopicItem
  ) {
    const dbRef = ref(getDatabase());
    const topicListRef = `sepContent/corp-courses/course-map/english/${courseId}/sectionList/${sectionId}/moduleList/${moduleId}/topicList/${item.topicId}`;
    const topicId = e.target.value;

    set(child(dbRef, topicListRef), topicId).then(() => {
      setTopicListState([]);
      setRefreshData(!refreshData);
    });
  }

  return (
    <AppContainer headerTitle={"Dashboard"}>
      <div className="container">
        {showSpinner && <SpinnerCenter />}
        <div className="row">
          <div
            className="column"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {/*back button*/}
            <Link
              to={`/corporate-course-list/module-list/${courseId}/${sectionId}`}
            >
              <button className={"button button-clear"}>Back</button>
            </Link>

            <h5> TOPICS </h5>
            <button
              onClick={() => setShowAddNewModal(true)}
              className={"button"}
            >
              +Add New Topic
            </button>
          </div>
        </div>
        <div className="row">
          <div className="column">
            {!topicListState.length && <p> No data</p>}
          </div>
        </div>
        <div className="row">
          <div className="column">
            <ul style={{ display: "flex", flexDirection: "column" }}>
              {topicListState &&
                topicListState.length > 0 &&
                _.sortBy(topicListState, "topicIndex").map(
                  (item: TopicItem, index) => (
                    <div className={"card"} key={item.topicId + index}>
                      <div className={"row "}>
                        <div className={"column"}>
                          <h5>
                            <b>{item.topicTitle}</b>
                          </h5>
                        </div>
                        <div className="column">
                          <button
                            className={"button button-clear"}
                            onClick={() => handleEditModal(item)}
                          >
                            Edit
                          </button>
                          <button
                            className={"button button-clear"}
                            onClick={() => deleteTopic(item.topicId)}
                          >
                            Delete
                          </button>{" "}
                          <button className={"button button-clear"}>
                            Preview
                          </button>
                          {/*<select*/}
                          {/*  name="nextTopicId"*/}
                          {/*  id="nextTopicId"*/}
                          {/*  value={item.nextTopicId}*/}
                          {/*  onChange={(e) => handleTopicChange(e, item)}*/}
                          {/*>*/}
                          {/*  <option value="">Select Next Topic</option>*/}
                          {/*  {topicListState.map((topic) => {*/}
                          {/*    return (*/}
                          {/*      <option value={topic.topicId}>*/}
                          {/*        {topic.topicTitle}*/}
                          {/*      </option>*/}
                          {/*    );*/}
                          {/*  })}*/}
                          {/*</select>*/}
                        </div>
                      </div>
                    </div>
                  )
                )}
            </ul>
          </div>
        </div>
        <div className={"row"}>
          <div className={"column"}>
            <div className="row ">
              {showAddModal && (
                <CreateNewTopicModal
                  setShowModal={setShowAddNewModal}
                  setRefreshData={setRefreshData}
                  refreshData={refreshData}
                  courseId={courseId}
                  sectionId={sectionId}
                  moduleId={moduleId}
                  topicListState={topicListState}
                />
              )}
            </div>
            <div className="row ">
              {showEditModal && (
                <EditTopicModal
                  setShowModal={setShowEditModal}
                  setRefreshData={setRefreshData}
                  refreshData={refreshData}
                  courseId={courseId}
                  sectionId={sectionId}
                  moduleId={moduleId}
                  currentTopicListData={currentTopic}
                  topicListState={topicListState}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default CorporateTopicList;
