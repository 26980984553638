import React, { useEffect, useState } from "react";
import "./modal.css";
import { child, getDatabase, ref, set } from "firebase/database";
import slugify from "slugify";
import SpinnerCenter from "../Spinners/SpinnerCenter";
import { Section } from "../../interfaces/corporate-courses.interface";
import moment from "moment";
import { checkNodeExists } from "../../services/coporatecourses.services";
import { getMaxFromArray } from "../../utils/mathutils";

const CreateNewSectionModal = ({
  setShowAddNewModal,
  setRefreshData,
  refreshData,
  courseId,
  sectionListState,
}) => {
  const [isCreateNewCourseModel, setCreateNewCourseModel] = useState(false);

  const [sectionTitle, setSectionTitle] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  // when sectionTitle changes, update sectionId state
  useEffect(() => {
    setSectionId(
      slugify(
        sectionTitle
          .replace(/[^\w\s]/gi, "")
          .toLowerCase()
          .trim()
      )
    );
  }, [sectionTitle]);

  async function clickNewSectionCreate() {
    const dbRef = ref(getDatabase());
    const childRef = `sepContent/corp-courses/course-map/english/${courseId}/sectionList/${sectionId}`;

    const pathExists = await checkNodeExists(childRef);
    if (pathExists) {
      alert(`This id already exists. Please choose another id.`);
      setShowSpinner(false);
      return;
    }

    const sectionIndex = getMaxFromArray(sectionListState, "sectionIndex") + 1;

    setShowSpinner(true);
    const sectionData: Section = {
      sectionIndex: sectionIndex,
      createdAt: moment().valueOf(),
      sectionTitle: sectionTitle,
    };

    set(child(dbRef, childRef), sectionData)
      .then((res) => {
        // alert("Course Updated");
        // window.location.reload();
        setShowAddNewModal(false);
        setRefreshData(!refreshData);
        setShowAddNewModal(false);
        setShowSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setShowAddNewModal(false);
        setShowSpinner(false);
      });
  }
  function handleClose() {
    if (
      window.confirm(
        "Are you sure you want to close? Any unsaved changes will be lost."
      )
    ) {
      setShowAddNewModal(false);
    }
  }
  return (
    <div className="modal-overlay">
      {showSpinner && <SpinnerCenter />}
      <div className="modal-content" onClick={handleModalClick}>
        <div className={"column"}>
          <input
            type="text"
            value={sectionTitle}
            name={"sectionTitle"}
            placeholder={"Section Title"}
            onInput={(e) =>
              setSectionTitle((e.target as HTMLInputElement).value)
            }
          />
          <input value={sectionId} type={"text"} name={"sectionId"} disabled />
        </div>
        <button disabled={sectionId === ""} onClick={clickNewSectionCreate}>
          Create
        </button>{" "}
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default CreateNewSectionModal;
