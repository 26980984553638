import React, { useEffect, useState } from "react";
import "./modal.css";
import { child, getDatabase, ref, set } from "firebase/database";
import slugify from "slugify";
import SpinnerCenter from "../Spinners/SpinnerCenter";
import { ModuleItem } from "../../interfaces/corporate-courses.interface";
import moment from "moment";
import { nanoid } from "nanoid";
import { getMaxFromArray } from "../../utils/mathutils";
import {checkNodeExists} from "../../services/coporatecourses.services";

const CreateNewModuleModal = ({
  setShowModal,
  setRefreshData,
  refreshData,
  courseId,
  sectionId,
  moduleListState,
}) => {
  const [moduleTitle, setModuleTitle] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  // when sectionTitle changes, update sectionId state
  useEffect(() => {
    setModuleId(
      slugify(
        moduleTitle
          .replace(/[^\w\s]/gi, "")
          .toLowerCase()
          .trim()
      ) +
        "-" +
        nanoid(5)
    );
  }, [moduleTitle]);

  async function clickNewModuleCreate() {
    if (moduleTitle === "") return;
    if (moduleId === "") return;
    if (courseId === "") return;
    if (sectionId === "") return;

    const dbRef = ref(getDatabase());

    const moduleIndex = getMaxFromArray(moduleListState, "moduleIndex") + 1;

    setShowSpinner(true);
    const sectionData: ModuleItem = {
      moduleIndex: moduleIndex,
      createdAt: moment().valueOf(),
      moduleTitle: moduleTitle,
    };

    const childRef = `sepContent/corp-courses/course-map/english/${courseId}/sectionList/${sectionId}/moduleList/${moduleId}`;
    const pathExists = await checkNodeExists(childRef);
    if (pathExists) {
      alert(`This id already exists. Please choose another id.`);
      setShowSpinner(false);
      return;
    }

    set(child(dbRef, childRef), sectionData)
      .then((res) => {
        // alert("Course Updated");
        // window.location.reload();
        setShowModal(false);
        setRefreshData(!refreshData);
        setShowSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setShowModal(false);
        setShowSpinner(false);
      });
  }

  function handleClose() {
    if (
      window.confirm(
        "Are you sure you want to close? Any unsaved changes will be lost."
      )
    ) {
      setShowModal(false);
    }
  }

  return (
    <div className="modal-overlay">
      {showSpinner && <SpinnerCenter />}
      <div className="modal-content" onClick={handleModalClick}>
        <div className={"column"}>
          <input
            type="text"
            value={moduleTitle}
            name={"moduleTitle"}
            placeholder={"Module Title"}
            onInput={(e) =>
              setModuleTitle((e.target as HTMLInputElement).value)
            }
          />
          <input
            style={{ display: "none" }}
            value={moduleId}
            type={"text"}
            name={"moduleId"}
            disabled
          />
        </div>
        <button disabled={moduleTitle === ""} onClick={clickNewModuleCreate}>
          Create
        </button>{" "}
        <button onClick={handleClose}>Close</button>
      </div>
    </div>
  );
};

export default CreateNewModuleModal;
