import {
  child,
  equalTo,
  get,
  getDatabase,
  orderByChild,
  query,
  ref,
  remove,
  set,
  update,
} from "firebase/database";
import { db } from "./firebaseApp";

// This is real time update on type or on change
export function updateCourseMapItems(path, value) {
  const dbRef = ref(getDatabase());
  const childRef = `sepContent/corp-courses/course-map/english/${path}`;
  set(child(dbRef, childRef), value)
    .then((res) => {
      console.log("course map updated");
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function checkNodeExists(path) {
  const dbRef = ref(getDatabase());
  const snapshot = await get(child(dbRef, path));
  return snapshot.exists();
}

// This is real time update on type or on change
export async function createNewCorpCourse(courseId, data) {
  const dbRef = ref(getDatabase());

  try {
    // await set(
    //   child(dbRef, `sepContent/corp-courses/courseList/english/${courseId}`),
    //   data
    // );
    await set(
      child(dbRef, `sepContent/corp-courses/course-map/english/${courseId}`),
      data
    );
    return "success";
  } catch (e) {
    return "error" + String(e);
  }
}

export async function updatePath(path, data) {
  const dbRef = ref(getDatabase());
  try {
    await set(child(dbRef, path), data);
    return "success";
  } catch (e) {
    return "error" + String(e);
  }
}

// Delete any node from firebase database by path
export async function handleDeleteCorpCourseNodes(courseId) {
  const dbRef = ref(getDatabase());
  try {
    await remove(
      child(dbRef, `sepContent/corp-courses/course-map/english/${courseId}`)
    );
    await remove(
      child(dbRef, `sepContent/corp-courses/courseList/english/${courseId}`)
    );
    return "done";
  } catch (e) {
    return "error" + String(e);
  }
}

export async function deleteSections(sectionId, courseId) {
  const dbRef = ref(getDatabase());
  try {
    await remove(
      child(
        dbRef,
        `sepContent/corp-courses/course-map/english/${courseId}/sectionList/${sectionId}`
      )
    );
    await remove(
      child(
        dbRef,
        `sepContent/corp-courses/courseList/english/${courseId}/${sectionId}`
      )
    );
    return "done";
  } catch (e) {
    return "error" + String(e);
  }
}

export async function deleteModules(moduleId, sectionId, courseId) {
  const dbRef = ref(getDatabase());
  try {
    await remove(
      child(
        dbRef,
        `sepContent/corp-courses/course-map/english/${courseId}/sectionList/${sectionId}/moduleList/${moduleId}`
      )
    );
    await remove(
      child(
        dbRef,
        `sepContent/corp-courses/moduleList/english/${courseId}/${sectionId}/${moduleId}`
      )
    );
    return "done";
  } catch (e) {
    return "error" + String(e);
  }
}
export async function deleteTopics(topicId, moduleId, sectionId, courseId) {
  const dbRef = ref(getDatabase());

  const updates = {};
  updates[
    `sepContent/corp-courses/course-map/english/${courseId}/sectionList/${sectionId}/moduleList/${moduleId}/topicList/${topicId}`
  ] = null;
  updates[
    `sepContent/corp-courses/topicList/english/${courseId}/${sectionId}/${moduleId}/${topicId}`
  ] = null;

  await update(dbRef, updates)
    .then((res) => {
      return "done";
    })
    .catch((err) => {
      return "error" + String(err);
    });

  return null;
}

export async function getCorporateCompanyList() {
  const dbRef = ref(getDatabase());

  const childRef = `sepContent/companies`;
  const snapshot = await get(child(dbRef, childRef));
  if (snapshot.exists()) {
    return snapshot.val();
  }
}

export async function addCorporateCompany(companyId, data) {
  const dbRef = ref(getDatabase());

  const childRef = `sepContent/companies/${companyId}`;
  const snapshot = await get(child(dbRef, childRef));
  if (!snapshot.exists()) {
    await set(child(dbRef, childRef), data);
    return true;
  }
}

export async function getUsersListPerCompanyPerCourse(
  companyId: string,
  courseId: string
) {
  const childRef = `userProgressCorp/${courseId}`;
  //query by company id
  const querySnapshot = query(
    ref(db, childRef),
    orderByChild("companyId"),
    equalTo(companyId)
  );
  const snapshot = await get(querySnapshot);
  if (snapshot.exists()) {
    return snapshot.val();
  }
}
