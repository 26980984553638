import React, { useEffect, useState } from "react";
import "./modal.css";
import { child, getDatabase, ref, set } from "firebase/database";
import slugify from "slugify";
import SpinnerCenter from "../Spinners/SpinnerCenter";
import moment from "moment";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { checkNodeExists } from "../../services/coporatecourses.services";
import { getMaxFromArray } from "../../utils/mathutils"; // Import Sun Editor's CSS File

const CreateNewTopicModal = ({
  setShowModal,
  setRefreshData,
  refreshData,
  courseId,
  sectionId,
  moduleId,
  topicListState,
}) => {
  const [topicTitle, setTopicTitle] = useState("");
  const [topicHtml, setTopicHtml] = useState("");
  const [topicImage, setTopicImage] = useState("");
  const [topicVideo, setTopicVideo] = useState("");
  const [topicVideoUrl, setTopicVideoUrl] = useState("");
  const [topicId, setTopicId] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  // when sectionTitle changes, update sectionId state
  useEffect(() => {
    setTopicId(
      slugify(
        topicTitle
          .replace(/[^\w\s]/gi, "")
          .toLowerCase()
          .trim()
      )
    );
  }, [topicTitle]);

  async function clickNewTopicCreate() {
    if (topicTitle === "") return;
    if (topicId === "") return;
    if (courseId === "") return;
    if (sectionId === "") return;

    const dbRef = ref(getDatabase());
    const topicIndex = getMaxFromArray(topicListState, "topicIndex") + 1;

    setShowSpinner(true);
    const topicData: any = {
      topicIndex: topicIndex,
      createdAt: moment().valueOf(),
      topicTitle: topicTitle,
      topicId: topicId,
      topicHtml: topicHtml,
    };
    const topicDataCourseMap = {
      topicIndex: topicIndex,
      topicId: topicId,
      topicTitle: topicTitle,
    };

    const childRef = `sepContent/corp-courses/topicList/english/${courseId}/${sectionId}/${moduleId}/${topicId}`;
    const topicIdCourseMapRef = `sepContent/corp-courses/course-map/english/${courseId}/sectionList/${sectionId}/moduleList/${moduleId}/topicList/${topicId}`;
    const pathExists = await checkNodeExists(childRef);
    if (pathExists) {
      alert(`This id already exists. Please choose another id.`);
      setShowSpinner(false);
      return;
    }
    set(child(dbRef, childRef), topicData)
      .then((res) => {
        setShowModal(false);
        setRefreshData(!refreshData);
        setShowSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setShowModal(false);
        setShowSpinner(false);
      });

    set(child(dbRef, topicIdCourseMapRef), topicDataCourseMap)
      .then((res) => {
        setRefreshData(!refreshData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowModal(false);
        setShowSpinner(false);
      });
  }

  function handleClose() {
    if (
      window.confirm(
        "Are you sure you want to close? Any unsaved changes will be lost."
      )
    ) {
      setShowModal(false);
    }
  }
  return (
    <div
      className="modal-overlay"
      style={{ display: "flex", flexDirection: "column", background: "grey" }}
    >
      {showSpinner && <SpinnerCenter />}
      <div className="modal-content" onClick={handleModalClick}>
        <div className={"column"}>
          <input
            type="text"
            value={topicTitle}
            name={"topicTitle"}
            placeholder={"Topic Title"}
            required={true}
            onInput={(e) => setTopicTitle((e.target as HTMLInputElement).value)}
          />
          <small>
            Preview URL:
            <a
              target={"_blank"}
              href={`https://solsedu.com/self-learning-courses/${topicId}`}
              rel="noreferrer"
            >{`https://solsedu.com/self-learning-courses/${topicId}`}</a>
          </small>

          {/*<RichtextEditor*/}
          {/*  html={topicHtml}*/}
          {/*  setFormHtml={setTopicHtml}*/}
          {/*  fieldLabel={"Topic Content"}*/}
          {/*  setHtmlError={() => {}}*/}
          {/*  key={"topicContent"}*/}
          {/*/>*/}
          {/*<ReactQuill*/}
          {/*  value={topicHtml}*/}
          {/*  onChange={handleContentChange}*/}
          {/*  style={{*/}
          {/*    height: "auto",*/}
          {/*    minHeight: "200px",*/}
          {/*    marginBottom: "20px",*/}
          {/*    marginTop: "20px",*/}
          {/*  }}*/}
          {/*  modules={modules}*/}
          {/*  formats={formats}*/}
          {/*/>*/}

          <SunEditor
            setOptions={{
              height: "500",
              buttonList: [
                [
                  "undo",
                  "redo",
                  "font",
                  "fontSize",
                  "formatBlock",
                  "paragraphStyle",
                  "blockquote",
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "subscript",
                  "superscript",
                  "fontColor",
                  "hiliteColor",
                  "textStyle",
                  "removeFormat",
                  "outdent",
                  "indent",
                  "align",
                  "horizontalRule",
                  "list",
                  "lineHeight",
                  "table",
                  "link",
                  "image",
                  "video",
                  "audio",
                  "fullScreen",
                  "codeView",
                  "print",
                ],
              ],
              imageUrlInput: true,
              videoUrlInput: true,
              charCounter: true,
            }}
            setDefaultStyle={
              "font-family: 'Verdana', sans-serif;font-size: 16px;"
            }
            defaultValue={topicHtml}
            onChange={(content) => setTopicHtml(content)}
          />

          {/*<input*/}
          {/*  type="text"*/}
          {/*  value={topicVideoUrl}*/}
          {/*  name={"topicVideoUrl"}*/}
          {/*  required={true}*/}
          {/*  placeholder={"Topic Video Url"}*/}
          {/*  onInput={(e) =>*/}
          {/*    setTopicVideoUrl((e.target as HTMLInputElement).value)*/}
          {/*  }*/}
          {/*/>*/}

          {/*<input*/}
          {/*  type="text"*/}
          {/*  value={topicImage}*/}
          {/*  name={"topicImage"}*/}
          {/*  placeholder={"Topic Image"}*/}
          {/*  onInput={(e) => setTopicImage((e.target as HTMLInputElement).value)}*/}
          {/*/>*/}
        </div>
      </div>
      <div className="row">
        <div
          className="column"
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            background: "grey",
            padding: "10px",
          }}
        >
          <button onClick={clickNewTopicCreate}>Create</button>{" "}
          <button onClick={handleClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default CreateNewTopicModal;
