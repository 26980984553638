import React, { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

const PublicRoute: FC<PublicRouteProps> = ({
  authenticated,
  ...routeProps
}) => {
  if (authenticated && routeProps.path === "/login") {
    return <Redirect to="/" />;
  }

  return <Route {...routeProps} />;
};

type PublicRouteProps = {
  authenticated: boolean;
} & RouteProps;

export default PublicRoute;
