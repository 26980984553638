import React from "react";

import fbAuth from "../services/fbAuth";
import { Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../pages/Login";
import Home from "../pages/Home";
import ClassReportByUid from "../pages/CommonPages/ClassReportByUid";
import LcBookings from "../pages/CommonPages/LcBookings";
import CorporateCourseList from "../pages/CorporateCourses/corporate-course-list";
import CorporateSectionList from "../pages/CorporateCourses/corporate-section-list";
import CorporateModuleList from "../pages/CorporateCourses/corporate-module-list";
import CorporateTopicList from "../pages/CorporateCourses/corporate-topic-list";
import AddUserToCourse from "../pages/add-user-to-course";
import ViewProgressReport from "../pages/view-user-progress";

interface Props {}

const Routes = () => {
  const user = fbAuth.currentUser;
  const authenticated = Boolean(user);

  return (
    <Switch>
      {/* admin */}
      {/* authentication */}
      <PublicRoute
        exact
        path="/login"
        component={Login}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path="/"
        component={Home}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path="/class-reports-by-uid"
        component={ClassReportByUid}
        authenticated={authenticated}
      />{" "}
      <PrivateRoute
        exact
        path="/add-user-to-course"
        component={AddUserToCourse}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path="/corporate-course-list"
        component={CorporateCourseList}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path="/corporate-course-list/section-list/:courseId"
        component={CorporateSectionList}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path="/corporate-course-list/module-list/:courseId/:sectionId"
        component={CorporateModuleList}
        authenticated={authenticated}
      />{" "}
      <PrivateRoute
        exact
        path="/corporate-course-list/topic-list/:courseId/:sectionId/:moduleId"
        component={CorporateTopicList}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path="/corporate-course-list/topic/:topicId"
        component={CorporateCourseList}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path="/corporate-course-list/course-reports/"
        component={CorporateCourseList}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path="/add-user-to-course"
        component={CorporateCourseList}
        authenticated={authenticated}
      />{" "}
      <PrivateRoute
        exact
        path="/view-user-progress"
        component={ViewProgressReport}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path="/lcBookings"
        component={LcBookings}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path="/student-report"
        component={ClassReportByUid}
        authenticated={authenticated}
      />
    </Switch>
  );
};

export default Routes;
