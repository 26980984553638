import React, { useEffect, useState } from "react";
import AppContainer from "../../containers/AppContainer";
import { child, get, getDatabase, ref, set } from "firebase/database";
import CreateNewCourseModal from "../../components/ModalPopups/CreateNewCourseModal";
import SpinnerCenter from "../../components/Spinners/SpinnerCenter";
import { Link } from "react-router-dom";
import { handleDeleteCorpCourseNodes } from "../../services/coporatecourses.services";
import _ from "lodash";
import EditNewCourseModal from "../../components/ModalPopups/EditNewCourseModal";

const slugify = require("slugify");

export async function getCorporateCoursesList() {
  const dbRef = ref(getDatabase());

  const childRef = `sepContent/corp-courses/course-map/english`;
  const snapshot = await get(child(dbRef, childRef));
  if (snapshot.exists()) {
    return snapshot.val();
  }
}

const CorporateCourseList = ({ courseListData }) => {
  const [courseListState, setCourseListState] = useState<any>([]);
  const [currentCourse, setCurrentCourse] = useState<any>({});
  const [newCourse, setNewCourse] = useState<any>({});
  const [slug, setSlug] = useState<string>("");
  const [courseTitleState, setCourseTitleState] = useState<string>("");
  const [sectionListArray, setSectionListArray] = useState<any[]>([]);
  const [repeatCountState, setRepeatCountState] = useState(10);
  const [idCollectionArray, setIdCollectionArray] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);
  const [showAddNewCourseModal, setShowAddNewCourseModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isSectionModalOpen, setSectionModalOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [refreshData, setRefreshData] = useState(false);
  useEffect(() => {
    const call = async () => {
      setShowSpinner(true);
      const data = await getCorporateCoursesList();
      if (!data) {
        setShowSpinner(false);
        return;
      }
      setCourseListState(Object.values(data));
      setShowSpinner(false);
    };

    call();
  }, [refreshData]);

  useEffect(() => {
    const call = async () => {};

    call();
  }, [isCourseModalOpen]);

  function handleCourseListClick(courseId) {
    const currentCourseLocal = courseListState.find(
      (item) => item.courseId === courseId
    );
    setCurrentCourse(currentCourseLocal);
    handleCourseModalOpen();
  }

  function handleFieldsChange(e) {
    setCurrentCourse({
      ...currentCourse,
      [e.target.name]: e.target.value,
    });
    handleAddNewInput(e);
  }

  function handleAddNewInput(e) {
    e.preventDefault();
    const value = e.target.value as string;
    // remove special characters
    if (value === "") return;
    setSlug(
      slugify(
        value
          .replace(/[^\w\s]/gi, "")
          .toLowerCase()
          .trim()
      )
    );
    setCourseTitleState(value);
  }

  async function addNewCorporateCourse(slug, courseTitle) {
    setShowSpinner(true);
    if (!slug || !courseTitle) {
      alert("Please write course title");
      return;
    }
    const dbRef = ref(getDatabase());
    const childRef = `sepContent/corp-courses/courseList/english/${slug}`;
    // check if slug exists
    const snapshot = await get(child(dbRef, childRef));
    //
    try {
      if (snapshot.exists()) {
        alert("Course ID already exists");
        setSlug("");
        setCourseTitleState("");
        setShowSpinner(false);
        return;
      }
    } catch (error) {
      console.log(error);
      setShowSpinner(false);
    }

    set(child(dbRef, childRef), {
      courseId: slug,
      courseTitle: courseTitle,
    })
      .then((res) => {
        alert("New Course Added");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleUpdateButtonClick() {
    setRefreshData(!refreshData);
    const dbRef = ref(getDatabase());
    const childRef = `sepContent/corp-courses/courseList/english/${currentCourse.courseId}`;
    set(child(dbRef, childRef), currentCourse)
      .then((res) => {
        // alert("Course Updated");
        // window.location.reload();
        setIsCourseModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleModalOpen = () => {
    setCurrentCourse(null);
    setIsModalOpen(true);
  };
  const handleCourseModalOpen = () => {
    setIsCourseModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsCourseModalOpen(false);
    setSectionModalOpen(false);
    setShowEditModal(false);
  };

  function handleViewSectionsClick(courseId) {
    const currentCourseLocal = courseListState.find(
      (item) => item.courseId === courseId
    );
    setCurrentCourse(currentCourseLocal);
    // get all sections
    const dbRef = ref(getDatabase());
    const childRef = `sepContent/corp-courses/sectionsList/english/${courseId}`;
    get(child(dbRef, childRef)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const idCollectionArrayLocal = Object.keys(data);
        setIdCollectionArray(idCollectionArrayLocal);
        setSectionListArray(Object.values(data));
      }
    });

    setSectionModalOpen(true);
  }

  function handleNewCourseFields(e) {
    setNewCourse({
      ...newCourse,
      [e.target.name]: e.target.value,
    });
  }

  async function confirmDeleteNode(courseId) {
    if (!window.confirm("Are you sure you want to delete this course?")) {
      return;
    }
    const response = await handleDeleteCorpCourseNodes(courseId);
    alert(response);
    setRefreshData(!refreshData);
  }

  function editCourse(courseId) {
    const currentCourseLocal = courseListState.find(
      (item) => item.courseId === courseId
    );
    setCurrentCourse(currentCourseLocal);
    setShowEditModal(true);
  }

  return (
    <AppContainer headerTitle={"Dashboard"}>
      <div className="container">
        {showSpinner && <SpinnerCenter />}
        <div className="row">
          <div
            className="column"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <b>Corporate Courses</b>
            <button
              onClick={() => setShowAddNewCourseModal(true)}
              className={"button button-clear"}
            >
              Add New Course
            </button>
          </div>
        </div>

        <div className="row">
          <div className="column">
            <ul style={{ display: "flex", flexDirection: "column" }}>
              {courseListState &&
                courseListState.length > 0 &&
                _.sortBy(courseListState, "createdAt").map((item) => (
                  <div className={"row card"} key={item.courseId}>
                    <div className={"column"}>
                      <b>{item.courseTitle}</b>
                    </div>
                    <div className="column">
                      <Link
                        to={`/corporate-course-list/section-list/${item.courseId}`}
                      >
                        <button className={"button button-clear"}>
                          Sections
                        </button>
                      </Link>
                      <button
                        className={"button button-clear"}
                        onClick={() => editCourse(item.courseId)}
                      >
                        Edit
                      </button>
                      <button
                        className={"button button-clear"}
                        onClick={() => confirmDeleteNode(item.courseId)}
                      >
                        Delete
                      </button>{" "}
                      <a
                        target={"_blank"}
                        href={`https://solsedu.com/self-learning-courses/${item.courseId}`}
                        rel="noreferrer"
                      >
                        View Live
                      </a>
                    </div>
                  </div>
                ))}
            </ul>

            <div className="row">
              <div className="column"></div>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div className={"column"}>
            <div className="row ">
              {showAddNewCourseModal && (
                <CreateNewCourseModal
                  setShowAddNewCourseModal={setShowAddNewCourseModal}
                  setRefreshData={setRefreshData}
                  refreshData={refreshData}
                  courseListState={courseListState}
                />
              )}
            </div>
            <div className="row ">
              {showEditModal && (
                <EditNewCourseModal
                  setModalOpen={setShowEditModal}
                  setRefreshData={setRefreshData}
                  refreshData={refreshData}
                  currentCourse={currentCourse}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default CorporateCourseList;
